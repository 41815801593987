import React, { useContext } from "react";
import "./Rightside.css";
import ProfileUser from "./ProfileUser/ProfileUser";
import Calendar from "./Calendar/Calendar";
import { UserContext } from "../../User Context/UserContext";

const RightSide = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="RightSide">
      <div className="top">
        {user && <ProfileUser />}
      </div>
      <div className="bottom">
        <Calendar />
      </div>
    </div>
  );
};

export default RightSide;