import React, { useState } from "react";
import "./Login.css";
import Caard from "../Caard/Caard";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import { auth, db, provider } from "../utils/FirebaseConfig/Firebase";
import { signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { Slide, toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages] = useState({});
  const navigate = useNavigate();
  const [value, setValue] = useState();

  // Render error messages
  const renderErrorMsg = (name) =>
    name === errorMessages.name && (
      <p className="error_msg">{errorMessages.message}</p>
    );

  const handleSignWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        setValue(data.user.email);
        localStorage.setItem("user", data.user.email);
        navigate("/dashboard");
      })
      .catch((error) => {
        console.error("Error Sign-In with Google", errorMessages);
        toast.error("Terjadi kesalahan saat Sign-In with Google");
      });
  };

  const login = async () => {
    console.log(emailOrUsername, password);
    try {
      let user;
      if (emailOrUsername.includes("@")) {
        // Login menggunakan email
        const userCredential = await signInWithEmailAndPassword(
          auth,
          emailOrUsername,
          password
        );
        user = userCredential.user;
      } else {
        // Login menggunakan username
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("username", "==", emailOrUsername));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 1) {
          const userDoc = querySnapshot.docs[0];
          const userData = userDoc.data();
          const userCredential = await signInWithEmailAndPassword(
            auth,
            userData.email,
            password
          );
          user = userCredential.user;
        } else {
          throw new Error("Username tidak ditemukan");
        }
      }

      console.log(user);

      // Ambil data pengguna dari Firestore
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      navigate("/dashboard");
      toast.success("Selamat Datang!");
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        toast.error("Email tidak ditemukan");
      } else if (error.code === "auth/wrong-password") {
        toast.error("Password salah");
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="LoginForm">
      <Caard>
        <h1 className="title">Sign In</h1>
        <p className="subtitle">Please log in using email/username and password!</p>
        <div className="inputs_container">
          <input
            type="text"
            placeholder="Email or Username"
            value={emailOrUsername}
            onChange={(e) => setEmailOrUsername(e.target.value)}
          />
          {renderErrorMsg("emailOrUsername")}
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {renderErrorMsg("password")}
          <a href="/forgotpass" className="forgot-password">
            Forgot password?
          </a>
        </div>
        <button onClick={login} className="login_button">
          Sign In
        </button>
        <div className="register-container">
          <a href="/register" className="register">
            Don't have an account? <strong>Register here.</strong>
          </a>
        </div>
        <div className="icons">
          <GoogleIcon onClick={handleSignWithGoogle} className="icon" />
        </div>
      </Caard>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </div>
  );
};

export default Login;