import React, { useState } from "react";
import "./Register.css";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../utils/FirebaseConfig/Firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const Register = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const navigate = useNavigate();

  const renderErrorMsg = (name) =>
    errorMessages[name] && (
      <p className="error_msg">{errorMessages[name]}</p>
    );
  const validateEmail = (email) => {
    // Regex untuk validasi email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateForm = () => {
    let isValid = true;
    const newErrorMessages = {};
    if (!email) {
      newErrorMessages.email = "Email is required";
      isValid = false;
    } else if (!validateEmail(email)) {
      newErrorMessages.email = "Please enter a valid email address";
      isValid = false;
    }
    if (!username) {
      newErrorMessages.username = "Username is required";
      isValid = false;
    }
    if (!password) {
      newErrorMessages.password = "Password is required";
      isValid = false;
    } else if (password.length < 6) {
      newErrorMessages.password = "Password must be at least 6 characters long";
      isValid = false;
    }
    setErrorMessages(newErrorMessages);
    return isValid;
  };
  const register = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, {
        uid: user.uid,
        email: user.email,
        username: username,
        location: "",
        bio: "",
      });

      toast.success("Berhasil Mendaftar");
      navigate("/login");
    } catch (error) {
      const errorCode = error.code;
      toast.error(errorCode);
    }
  };

  return (
    <div className="Register">
      <div className="card">
        <h1 className="title">Register</h1>
        <p className="subtitle">Please register using email and password!</p>
        <div className="inputs_container">
          <div className="input_group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {renderErrorMsg("email")}
          </div>
          <div className="input_group">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {renderErrorMsg("username")}
          </div>
          <div className="input_group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {renderErrorMsg("password")}
          </div>
        </div>
        <button onClick={register} className="register_button">
          Register
        </button>
        <div className="login-container">
          <a href="/#login" className="login">
            Already have an account? <strong>Login here</strong>.
          </a>
        </div>
      </div>
    </div>
  );
};

export default Register;