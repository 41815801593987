import React, { useContext } from "react";
import './Dashboard.css';
import Sidebar from './Sidebar/Sidebar';
import MainDash from './Main Dash/MainDash';
import RightSide from './Rightside/Rightside';
import { UserContext } from "../User Context/UserContext";

const Dashboard = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="dashboard">
      <div className="dashboardglass">
        <Sidebar />
        <MainDash />
        <RightSide user={user} />
      </div>
    </div>
  );
};

export default Dashboard;