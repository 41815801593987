import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth, GoogleAuthProvider} from "firebase/auth";
import { getFirestore, doc } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDosb_Iyimf1lSukL_APGSe1YmmO-LkCro",
  authDomain: "biogas-3eac2.firebaseapp.com",
  databaseURL: "https://biogas-3eac2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "biogas-3eac2",
  storageBucket: "biogas-3eac2.appspot.com",
  messagingSenderId: "322605028525",
  appId: "1:322605028525:web:70e8de8f41b5fa17446224",
  measurementId: "G-TD7T6R8GWK"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const realtimeDb = getDatabase(app);
const storage = getStorage(app);

export { auth, db, provider, realtimeDb, storage };