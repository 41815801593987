import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../img/lilili.png';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setClicked(!clicked);
  };

  const navigateToPath = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setClicked(false);
    }
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setClicked(false);
  }, [location.pathname]);

  return (
    <nav className={scrolled ? 'nav-scrolled' : ''}>
      <div className="logonav">
        <img src={Logo} alt="Logo" />
      </div>
      <div>
        <ul id="navbar" className={clicked ? 'navbar active' : 'navbar'}>
          <li>
            <a className="active" onClick={() => navigateToPath('homeSection')}>
              Home
            </a>
          </li>
          <li>
            <a onClick={() => navigateToPath('aboutSection')}>About Us</a>
          </li>
          <li>
            <a onClick={() => navigateToPath('loginSection')}>Login</a>
          </li>
        </ul>
      </div>
      <div id="mobile" onClick={handleClick}>
        <FontAwesomeIcon icon={clicked ? faTimes : faBars} />
      </div>
    </nav>
  );
};

export default Navbar;