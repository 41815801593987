// UserContext.js
import React, { createContext, useState, useEffect } from "react";
import { auth, db } from "../utils/FirebaseConfig/Firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const userDocRef = doc(db, "users", authUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUser(userDoc.data());
        } else {
          // Jika dokumen pengguna tidak ada, buat data pengguna baru
          const newUser = {
            uid: authUser.uid,
            email: authUser.email,
            username: "",
            location: "",
            bio: "",
            // Tambahkan field lain sesuai kebutuhan
          };
          await setDoc(userDocRef, newUser);
          setUser(newUser);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const updateUser = async (updatedData) => {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, { ...user, ...updatedData }, { merge: true });
      setUser({ ...user, ...updatedData });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};