import React, { useState, useEffect, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { realtimeDb } from '../../../utils/FirebaseConfig/Firebase';
import { ref, onValue, off } from 'firebase/database';
import './SensorChart.css';

const SensorChart = ({ sensorType, chartColor }) => {
  const [historyData, setHistoryData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const historyRef = ref(realtimeDb, 'History');

    const handleData = (snapshot) => {
      try {
        const data = snapshot.val();
        if (data) {
          console.log("Raw history data:", data);
          const historyArray = Object.entries(data).map(([timestamp, values]) => ({
            timestamp,
            ...values
          }));
          setHistoryData(historyArray);
          setError(null);
        } else {
          setHistoryData([]);
          setError("No data available");
        }
      } catch (err) {
        console.error("Error processing Firebase data:", err);
        setError("Error processing data");
      }
    };

    onValue(historyRef, handleData, (err) => {
      console.error("Firebase onValue error:", err);
      setError("Failed to fetch data");
    });

    return () => off(historyRef, 'value', handleData);
  }, []);

  const seriesData = useMemo(() => {
    if (historyData.length === 0) return [];

    let dataKey;
    switch (sensorType.toLowerCase()) {
      case 'suhu': dataKey = 'Suhu'; break;
      case 'tekanan': dataKey = 'Tekanan'; break;
      case 'kadar gas metana': dataKey = 'Gas_Metana'; break;
      case 'ph': dataKey = 'PH'; break;
      default:
        console.log("Unknown sensor type:", sensorType);
        return [];
    }

    return historyData
      .slice(-20)
      .map(row => ({
        x: row.timestamp,
        y: parseFloat(row[dataKey])
      }))
      .filter(item => !isNaN(item.y));
  }, [historyData, sensorType]);

  const chartOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        },
        export: {
          csv: {
            filename: `${sensorType}_data`,
            columnDelimiter: ',',
            headerCategory: 'Timestamp',
            headerValue: sensorType,
          },
          svg: {
            filename: `${sensorType}_chart`,
          },
          png: {
            filename: `${sensorType}_chart`,
          }
        },
        autoSelected: 'zoom'
      },
      animations: {
        enabled: false
      },
      events: {
        beforeExport: function(ctx) {
          const originalOptions = { ...ctx.w.config };
          
          // Mengubah opsi untuk ekspor
          ctx.w.config.chart.background = '#000000';
          ctx.w.config.colors = ['#000000'];
          ctx.w.config.xaxis.labels.style.colors = '#000000';
          ctx.w.config.yaxis.labels.style.colors = '#000000';
          ctx.w.config.yaxis.title.style.color = '#000000';
          ctx.w.config.title.style.color = '#000000';
          
          // Mengembalikan opsi asli setelah ekspor
          setTimeout(() => {
            Object.assign(ctx.w.config, originalOptions);
            ctx.w.globals.initialConfig = { ...originalOptions };
            ctx.w.update();
          }, 0);
        }
      }
    },
    title: {
      text: sensorType,
      align: 'center',
      style: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#FFFFFF'
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    colors: ['#FFFFFF'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      type: 'category',
      labels: {
        rotate: -45,
        rotateAlways: true,
        formatter: function(value) {
          if (typeof value === 'string' && value.includes(' at ')) {
            const [, timePart] = value.split(' at ');
            return timePart;
          }
          return value;
        },
        style: {
          colors: '#FFFFFF',
          fontSize: '10px'
        }
      }
    },
    yaxis: {
      title: {
        text: sensorType,
        style: {
          color: '#FFFFFF',
          fontSize: '15px',
          fontWeight: 'bold'
        }
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(3);
        },
        style: {
          colors: '#FFFFFF',
          fontSize: '12px'
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toFixed(3);
        }
      },
      theme: 'dark'
    }
  };

  if (error) {
    return <div className="sensor-chart-error">Error: {error}</div>;
  }

  if (seriesData.length === 0) {
    return <div className="sensor-chart-no-data">No data available for chart</div>;
  }

  return (
    <div className="sensor-chart-container">
      <Chart 
        options={chartOptions} 
        series={[{ name: sensorType, data: seriesData }]} 
        type="bar" 
        height={435}
        width="100%"
        className="sensor-chart"
      />
      <div className="chart-footer">20 data terakhir</div>
    </div>
  );
};

export default SensorChart;