import React, { useContext } from "react";
import "./ProfileUser.css";
import { UserContext } from "../../../User Context/UserContext";
import { FaUser } from "react-icons/fa";

const ProfileUser = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="user-profile">
      <div className="avatar">
        <FaUser className="user-icon" />
      </div>
      <div className="user-info">
        <h3 className="username">{user?.username}</h3>
        <p className="email">{user?.email}</p>
      </div>
    </div>
  );
};

export default ProfileUser;