import React from "react";
import './Sidebar.css';
import Logo from '../../../img/simbol.png';
import { UilEstate, UilSignOutAlt } from '@iconscout/react-unicons';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const [selected, setSelected] = React.useState(0);
    const navigate = useNavigate();

    const handleLogoutClick = () => {
        navigate('/');
    }

    const handleMenuItemClick = (index) => {
        setSelected(index);
        if (window.innerWidth <= 768) {
            toggleSidebar();
        }
        switch (index) {
            case 0:
                navigate('/dashboard');
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className={`sidebar-overlay ${isOpen ? 'active' : ''}`} onClick={toggleSidebar}></div>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-content">
                    <div className="logo">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <div className="menu">
                        <div
                            className={selected === 0 ? 'menuItem active' : 'menuItem'}
                            onClick={() => handleMenuItemClick(0)}
                        >
                            <UilEstate />
                            <span>Dashboard</span>
                        </div>
                    </div>
                </div>
                <div className="menuItem logout" onClick={handleLogoutClick}>
                    <UilSignOutAlt />
                    <span>Logout</span>
                </div>
            </div>
            <button className="sidebar-toggle" onClick={toggleSidebar}>
                <i className="fas fa-bars"></i>
            </button>
        </>
    );
};

export default Sidebar;