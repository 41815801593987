import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import { realtimeDb } from '../../../utils/FirebaseConfig/Firebase';
import { ref, onValue } from 'firebase/database';
import TablePagination from '@mui/material/TablePagination';
import './Table.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import TextField from '@mui/material/TextField';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

// Aktifkan plugin dayjs
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export default function SensorDataTable() {
  const [historyData, setHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const historyRef = ref(realtimeDb, 'History');

    const unsubscribe = onValue(historyRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        console.log("History data from Firebase:", data);
        const historyArray = Object.entries(data).map(([timestamp, values]) => ({
          timestamp,
          ...values
        }));
        setHistoryData(historyArray.reverse());
        filterData(historyArray, startDate, endDate);
      } else {
        setHistoryData([]);
        setFilteredData([]);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    filterData(historyData, startDate, endDate);
  }, [startDate, endDate]);

  const filterData = (data, start, end) => {
    if (!start || !end) {
      setFilteredData(data);
      return;
    }

    const filtered = data.filter(item => {
      const itemDate = dayjs(item.timestamp.split(' at ')[0], 'DD-MM-YYYY');
      return itemDate.isSameOrAfter(dayjs(start), 'day') && itemDate.isSameOrBefore(dayjs(end), 'day');
    });

    setFilteredData(filtered);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(dayjs(newValue));
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(dayjs(newValue));
  };

  const resetDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setFilteredData(historyData);
  };

  const formatTimestamp = (timestamp) => {
    const [date, time] = timestamp.split(' at ');
    return (
      <div className="timestamp-container">
        <div className="timestamp-date">{date}</div>
        <div className="timestamp-time">{time}</div>
      </div>
    );
  };

  const convertToCSV = (data) => {
    const header = ['Timestamp', 'Suhu', 'Tekanan', 'Gas', 'pH'];
    const csvRows = [
      header.join(','),
      ...data.map(row => [
        row.timestamp,
        parseFloat(row.Suhu).toFixed(2),
        parseFloat(row.Tekanan).toFixed(2),
        parseFloat(row.Gas_Metana).toFixed(5),
        parseFloat(row.PH).toFixed(5)
      ].join(','))
    ];
    return csvRows.join('\n');
  };

  const downloadCSV = () => {
    const csv = convertToCSV(filteredData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'sensor_data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className='table'>
      <h3>History Data Sensor</h3>
      <Box className="filter-container">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} className="date-picker" />}
            maxDate={dayjs()}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => <TextField {...params} className="date-picker" />}
            maxDate={dayjs()}
            minDate={startDate}
          />
        </LocalizationProvider>
        <Button
          variant="contained"
          onClick={resetDateFilter}
          className="reset-button"
          startIcon={<RestartAltIcon />}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<DownloadIcon />}
          onClick={downloadCSV}
          className="download-button"
        >
          Download CSV
        </Button>
      </Box>
      <TableContainer component={Paper} className="table-container">
        <Table aria-label="sensor data table" size="small" className="compact-table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell align="right">Suhu</TableCell>
              <TableCell align="right">Tekanan</TableCell>
              <TableCell align="right">Gas</TableCell>
              <TableCell align="right">pH</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{formatTimestamp(row.timestamp)}</TableCell>
                  <TableCell align="right">{parseFloat(row.Suhu).toFixed(2)}</TableCell>
                  <TableCell align="right">{parseFloat(row.Tekanan).toFixed(2)}</TableCell>
                  <TableCell align="right">{parseFloat(row.Gas_Metana).toFixed(5)}</TableCell>
                  <TableCell align="right">{parseFloat(row.PH).toFixed(5)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export { SensorDataTable };