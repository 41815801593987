import React, { useState, useEffect } from 'react';
import './Cards.css';
import { UilClipboardAlt } from '@iconscout/react-unicons';
import { FaTemperatureFull } from 'react-icons/fa6';
import { WiBarometer } from 'react-icons/wi';
import Card from '../Card/Card';
import { realtimeDb } from '../../../utils/FirebaseConfig/Firebase';
import { ref, onValue } from 'firebase/database';

const Cards = () => {
  const [sensorData, setSensorData] = useState({});

  useEffect(() => {
    const biogasIoTRef = ref(realtimeDb, 'Biogas_IoT');

    const unsubscribe = onValue(biogasIoTRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        console.log("Data from Firebase:", data);
        setSensorData(data);
      } else {
        setSensorData({});
      }
    });

    return () => unsubscribe();
  }, []);

  const CardsData = [
    {
      title: 'Suhu',
      color: {
        background: 'var(--cyan)',
        boxShadow: 'var(--smboxShadow)',
      },
      barValue: parseFloat(sensorData.Suhu) || 0,
      value: `${sensorData.Suhu || ''} °C`,
      png: FaTemperatureFull,
    },
    {
      title: 'Tekanan',
      color: {
        background: 'var(--pressure)',
        boxShadow: 'var(--smboxShadow)',
      },
      barValue: parseFloat(sensorData.Tekanan) || 0,
      value: `${sensorData.Tekanan || ''} Pa`,
      png: WiBarometer,
    },
    {
      title: 'Kadar Gas Metana',
      color: {
        background: 'var(--gas)',
        boxShadow: 'var(--smboxShadow)',
      },
      barValue: parseFloat(sensorData.Gas_Metana) || 0,
      value: `${sensorData.Gas_Metana || ''} ppm`,
      png: UilClipboardAlt,
    },
    {
      title: 'pH',
      color: {
        background: 'var(--ph)',
        boxShadow: 'var(--smboxShadow)',
      },
      barValue: parseFloat(sensorData.PH) || 0,
      value: sensorData.PH || '',
      png: UilClipboardAlt,
    },
  ];

  return (
    <div className="cards-container">
      {CardsData.map((card, id) => (
        <div className="card-wrapper" key={id}>
          <Card
            title={card.title}
            color={card.color}
            barValue={card.barValue}
            value={card.value}
            png={card.png}
            range={card.range}
          />
        </div>
      ))}
    </div>
  );
};

export default Cards;