import React, { useState, memo } from 'react';
import './Card.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { UilTimes } from '@iconscout/react-unicons';
import SensorChart from './SensorChart';
import { ka } from 'date-fns/locale';

const Card = memo((props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <TransitionGroup>
      {expanded ? (
        <CSSTransition key="expanded" classNames="fade" timeout={300}>
          <ExpandedCard param={props} setExpanded={() => setExpanded(false)} />
        </CSSTransition>
      ) : (
        <CSSTransition key="compact" classNames="fade" timeout={300}>
          <CompactCard param={props} setExpanded={() => setExpanded(true)} />
        </CSSTransition>
      )}
    </TransitionGroup>
  );
});

const CompactCard = memo(({ param, setExpanded }) => {
  const Png = param.png;
  
  const getStatusMessage = (title, value) => {
    if (title === 'Suhu') {
      const temp = parseFloat(value);
      if (temp < 25) return 'Nilai di bawah batas ideal';
      if (temp > 40) return 'Nilai di atas batas ideal';
      return 'Nilai ideal';
    }
    if (title === 'Tekanan') {
      const tekanan = parseFloat(value);
      if (tekanan < 1000) return 'Nilai di bawah batas ideal';
      if (tekanan > 1000000) return 'Nilai di atas batas ideal';
      return 'Nilai tidak terdeteksi';
    }
    if (title === 'pH') {
      const ph = parseFloat(value);
      if (ph < 6.5) return 'Nilai di bawah batas ideal';
      if (ph > 7.5) return 'Nilai di atas batas ideal';
      return 'Nilai ideal';
    }
    if (title === 'Kadar Gas Metana') {
      const kadargas = parseFloat(value);
      if (kadargas <= 1000) return 'Nilai di bawah batas ideal';
      if (kadargas > 10000) return 'Nilai di atas batas ideal';
      return 'Nilai ideal';
    }
    
    return '';
  };

  return (
    <div
      className="compactcard"
      style={{
        background: param.color.background,
        boxShadow: param.color.boxShadow,
      }}
      onClick={setExpanded}
    >
      <div className="card-header">
        <span className="sensorTitle">{param.title}</span>
        <Png className="sensorIcon" />
      </div>
      <div className="card-content">
        <span className="sensorValue">{param.value}</span>
        {param.range && <span className="sensorRange">Range: {param.range}</span>}
        <span className="statusMessage">{getStatusMessage(param.title, param.value)}</span>
      </div>
    </div>
  );
});

const ExpandedCard = memo(({ param, setExpanded }) => {
  return (
    <div
      className="expandedcard"
      style={{
        background: param.color.background,
        boxShadow: param.color.boxShadow,
      }}
    >
      <div style={{ alignSelf: 'flex-end', cursor: 'pointer', color: 'var(--black)' }}>
        <UilTimes onClick={setExpanded} />
      </div>
      <div className="chartcontainer">
        <SensorChart 
          sensorType={param.title} 
          chartColor={param.color.background}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  );
})

export default Card;