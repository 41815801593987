import React from "react";
import "./AboutUs.css";
import Gambar from '../../img/biogas.jpg';

const AboutUs = () => {
  return (
    <section id="aboutSection" className="About">
      <div className="content-container">
        <h2>
          <span>About</span> Us
        </h2>
        <p className="intro">
          BioSmart Monitoring adalah perusahaan terdepan dalam solusi monitoring biogas yang inovatif dan canggih. Kami mengombinasikan teknologi terbaru dengan pengalaman ahli untuk membantu Anda mengoptimalkan produksi biogas dan mencapai target keberlanjutan.
        </p>
        <div className="row">
          <div className="content">
            <h3>Mengapa Memilih BioSmart Monitoring?</h3>
            <ul>
              <li>Pemantauan real-time parameter kunci sistem biogas</li>
              <li>Dashboard intuitif yang menampilkan metrik penting</li>
              <li>Peningkatan efisiensi dan produksi berbasis data</li>
            </ul>
            <h4>Teknologi:</h4>
            <ul>
              <li>Sensor yang akurat dan andal</li>
              <li>Platform berbasis cloud yang aman</li>
              <li>Antarmuka pengguna yang dirancang untuk kemudahan penggunaan</li>
            </ul>
          </div>
          <div className="image-container">
            <img src={Gambar} alt="Biogas Plant" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;