import { useState } from "react"
import Caard from "../../Caard/Caard"
import "./ForgotPassword.css"
import { auth } from "../../utils/FirebaseConfig/Firebase"
import { toast } from "react-toastify"
import { sendPasswordResetEmail } from "firebase/auth"
import { FaSpinner } from "react-icons/fa"

const ForgotPassword = () => {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [emailError, setEmailError] = useState("")

  const handleReset = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!email) {
      setEmailError("Email is required")
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email address")
    } else {
      setEmailError("")
      setIsLoading(true)
      sendPasswordResetEmail(auth, email)
        .then(() => {
          toast.success("Password reset email sent")
          setEmail("")
          setIsLoading(false)
        })
        .catch((error) => {
          const errorCode = error.code
          const errorMessage = error.message
          toast.error(`${errorCode}: ${errorMessage}`)
          setIsLoading(false)
        })
    }
  }

  return (
    <div className="ForgotPassword">
      <Caard>
        <h1 className="title">Forgot Password</h1>
        <p className="subtitle">Enter your email to reset your password.</p>
        <div className="inputs_container">
          <div className="input_group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <p className="error_message">{emailError}</p>}
          </div>
        </div>
        <button
          onClick={handleReset}
          className="reset_button"
          disabled={isLoading}
        >
          {isLoading ? (
            <FaSpinner className="spinner" />
          ) : (
            "Reset Password"
          )}
        </button>
        <div className="login-container">
          <a href="/" className="login">
            Back to Login
          </a>
        </div>
      </Caard>
    </div>
  );
};

export default ForgotPassword;