import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Home from '../Home Page/Home';
import AboutUs from '../About Us Page/AboutUs';
import Login from '../Login Page/Login';

const LandingPage = () => {
  const loginSectionRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#login') {
      loginSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div>
      <Home />
      <AboutUs />
      <section ref={loginSectionRef} id="loginSection">
        <Login />
      </section>
    </div>
  );
};

export default LandingPage;