import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import LandingPage from './components/Landing Page/LandingPage';
import Login from './components/Login Page/Login';
import Register from './components/Register Page/Register';
import Dashboard from './components/Dashboard/Dashboard';
import ForgotPassword from './components/Forgot Password Setting/Forgot Password/ForgotPassword';
import { UserProvider } from './components/User Context/UserContext';
import { Navigate } from 'react-router-dom';

const LayoutWithNavbar = ({ children }) => (
  <>
    <Navbar />
    {children}
  </>
);

const LoginRedirect = () => <Navigate to="/#login" replace />;

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<LayoutWithNavbar><LandingPage /></LayoutWithNavbar>} />
      <Route path="/register" element={<Register/>} />
      <Route path="/forgotpass" element={<ForgotPassword/>} />
      <Route path="/login" element={<LoginRedirect/>} />
      
      <Route path="/dashboard" element={
        <UserProvider>
          <Dashboard />
        </UserProvider>
      } />
      
    </Routes>
  );
};

export default App;