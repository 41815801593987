import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <section className="home" id="homeSection">
      <div className="content-container">
        <div className="content">
          <h1>Selamat datang di BioSmart Monitoring: Revolusi Pengelolaan Biogas</h1>
          <div className="features">
            <div className="feature">
              <i className="fas fa-clock"></i>
              <h3>Pemantauan<br />Real-Time</h3>
              <p>Pantau parameter kunci biogas secara langsung</p>
            </div>
            <div className="feature">
              <i className="fas fa-chart-line"></i>
              <h3>Analisis<br />Canggih</h3>
              <p>Dapatkan wawasan mendalam untuk optimalisasi</p>
            </div>
            <div className="feature">
              <i className="fas fa-database"></i>
              <h3>Akurasi<br />Data</h3>
              <p>Peroleh data yang akurat dan andal</p>
            </div>
            <div className="feature">
              <i className="fas fa-tachometer-alt"></i>
              <h3>Dashboard<br />Terintegrasi</h3>
              <p>Nikmati antarmuka yang menyatu dan mudah dipahami</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;